<template>
  <div v-if="isLoading" class="h-[700px] mobile:h-[500px]">

  </div>
  <div v-else>
    <div v-if="profile"
         class="pt-[66px] pb-[438px] mobile:pt-[28px] mobile:pb-[190px] mobile:px-[16px] flex flex-col gap-[60px] mobile:gap-[28px] items-center">
      <h1 class="text-[24px] font-[Suit-Bold] leading-[44px] w-full">나의 정보</h1>
      <div class="flex flex-col gap-[40px] items-center w-full">
        <div class="flex w-full gap-[40px] mobile:flex-col mobile:gap-[20px]">
          <div class="flex w-full flex-col gap-[30px] mobile:gap-[20px]">
            <ProfileItem title="First Name" :value="profile.first_name" />
            <ProfileItem title="Last Name" :value="profile.last_name" />
            <ProfileItem title="Phone Num" :value="profile.phone" />
            <ProfileItem title="Email" :value="profile.email" />
          </div>
          <div class="flex w-full flex-col gap-[30px] mobile:gap-[20px]">
            <ProfileItem title="Zipcode" :value="profile.zipcode" />
            <div class="flex items-start">
              <p class="flex-shrink text-[16px] text-darkDark leading-[28px] w-[150px] mobile: w-[100px]">Address</p>

              <div v-if="profile.default_address/* && profile.detail_address*/">
                <p v-if="profile.default_address" class="text-[16px] text-fifth flex-grow">{{profile.default_address}}</p>
                <!--
                  <p v-if="profile.detail_address" class="text-[16px] text-fifth flex-grow">{{profile.detail_address}}</p>
                -->
              </div>
              <p class="text-[16px] text-lightLightDark" v-else>입력없음</p>

            </div>
            <ProfileItem title="개인통관번호" :value="profile.customer_number" />
          </div>
        </div>


        <div @click="$router.push('/users/mypage/profile/edit')"
             class="mobile:w-full w-[455px] h-[52px] bg-[#A8221A] text-center text-white leading-[52px] cursor-pointer rounded-[5px]">
          수정하기
        </div>
      </div>

    </div>
    <div v-else
         class="pt-[66px] pb-[438px] mobile:pt-[28px] mobile:pb-[135px] mobile:px-[16px] flex flex-col gap-[74px] mobile:gap-[107px] items-center">
      <h1 class="text-[24px] font-[Suit-Bold] leading-[44px] w-full">나의 정보</h1>
      <div class="flex flex-col gap-[50px] mobile:gap-[40px]">
        <div class="flex flex-col gap-[20px] items-center">
          <img class="w-[60px] h-[60px]" src="~@/assets/images/mypage/my-page-profile.png"/>
          <div class="flex flex-col gap-[4px] items-center">
            <p class="text-[20px] font-[Suit-Bold] leading-[36px]">입력된 정보가 없습니다</p>
            <p class="text-[14px] leading-[24px]">원할한 서비스 이용을 위해 정보 입력이 필요합니다</p>
          </div>
        </div>

        <div @click="$router.push('/users/mypage/profile/edit')"
             class="w-full mobile:w-[328px] w-[455px] h-[52px] bg-[#A8221A] text-center text-white leading-[52px] cursor-pointer rounded-[5px]">
          정보 입력하기
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import getContractApi from "@/api/contract";
import { mapState } from "vuex";
import ProfileItem from "@/components/mypage/ProfileItem";

export default {
  components: {ProfileItem},
  computed: {
    ...mapState('userProfile', ['userProfile'])
  },
  created() {
    this.market = getContractApi();
  },
  data(){
    return{
      profile: null,
      isLoading: true
    }
  },
  mounted() {
    // let self = this
    this.market.getWineMarket().getUserAddress().then(data => {
      if (data === undefined) {
        alert('메타마스크 연결을 확인해 주세요.')
        location.href = '/'
      } else {

        const _this = this;
        this.$store.dispatch('userProfile/getUserProfile', {wallet_address: data}).then(res => {
          _this.profile = res;
          _this.isLoading = false;
        })
      }

    })

  },


}
</script>

<style>
p {
  margin: 0 !important;
}
</style>